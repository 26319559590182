<template>
  <v-container :loading="loading" fluid>
    <UploadPhotoDialog
        ref="upload"
        @photo-uploaded="getDataFromAPI()"
      />
    <CoolLightBox
      :items="items"
      :index="index"
      :useZoomBar="true"
      @close="index = null"
      v-if="loading == false"
    >
    </CoolLightBox>
    <ConfirmDeleteDialog ref="confirm" />
    
    <v-card :loading="loading">
      <v-toolbar color="primary">
        <!--<v-toolbar-title>
          <v-icon medium> mdi-handshake </v-icon>
        รูปภาพการเข้าพบลูกค้า
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          small
          color="secondary"
          class="ma-2"
          outlined
          @click="openUploadPhotoDialog(id)"
        >
          <v-icon small> mdi-file-upload </v-icon>
          Upload
        </v-btn>     
      </v-toolbar> -->
   <v-card-title>
        <v-icon medium> mdi-handshake </v-icon>
        รูปภาพการเข้าพบลูกค้า
        <v-spacer />
        <v-btn
          small
          color="secondary"
          class="ma-2"
          outlined
          @click="openUploadPhotoDialog(id)"
        >
          <v-icon small> mdi-file-upload </v-icon>
          Upload
        </v-btn>
      </v-card-title> 
        </v-toolbar> 
      <v-card-text>
        <v-row v-if="loading == false">
          <v-col
            v-for="(photo, photoIndex) in customerMeeting.customerMeetingPhotos"
            :key="photoIndex"
            class="d-flex child-flex"
            cols="12"
            xl="2"
            lg="2"
            md="3"
            sm="3"
            xs="12"
          >
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 12 : 2"
                :class="{ 'on-hover': hover }"
              >
                <v-img
                  :src="`${photoThumbnailPrefix}${photo.filename}`"
                  aspect-ratio="1"
                  @click="index = photoIndex"
                  class="grey lighten-2"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-card-actions>
                  <v-btn
                    icon
                    @click="redirect(`${photoPrefix}${photo.filename}`)"
                  >
                    <v-icon small color="secondary">mdi-open-in-new</v-icon>
                  </v-btn>
                  <v-spacer />
                  <v-btn icon @click="confirmDeletePhoto(photo)">
                    <v-icon small color="secondary">mdi-delete</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import UploadPhotoDialog from "./UploadPhotoDialog";
import ConfirmDeleteDialog from "../../Shared/ConfirmDeleteDialog";
import axios from "axios";

export default {
  name: "CustomerMeetingPhoto",
  components: {
    UploadPhotoDialog,
    ConfirmDeleteDialog,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    id: {
      type: String,
    },
  },
  data: function () {
    return {
      customerMeeting: {},
      photoPrefix:
        process.env.VUE_APP_STATIC_BASE_URL + "images/customer-meeting/",
      photoThumbnailPrefix:
        process.env.VUE_APP_STATIC_BASE_URL +
        "images/customer-meeting/thumbnail_",
      deletePhotoResponse: {},
      index: null,
      loading: false,
    };
  },
  computed: {
    items: {
      cache: false,
      get() {
        if (this.customerMeeting.customerMeetingPhotos.length != 0) {
          let itemArray = this.customerMeeting.customerMeetingPhotos.map(
            (item) => {
              const photoItem = {};
              photoItem.src = this.photoPrefix + item.filename;
              photoItem.thumb = this.photoThumbnailPrefix + item.filename;
              return photoItem;
            }
          );
          return itemArray;
        } else {
          return [];
        }
      },
    },
  },
  created() {
    this.getDataFromAPI();
  },
  methods: {
    getDataFromAPI: function () {
      this.loading = true;
      axios
        .get("/customer-meeting/" + this.id + "/photos")
        .then((response) => {
          // JSON responses are automatically parsed.
          this.customerMeeting = response.data;
          this.loading = false;
          this.$forceUpdate();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    redirect: function (link, target = "_blank") {
      // console.log(link);
      window.open(link, target);
    },
    openUploadPhotoDialog: async function(id) {
      await this.$refs.upload.open(id);
    },
    confirmDeletePhoto: async function (photo) {
      if (
        await this.$refs.confirm.open(
          "ยืนยันการลบรูปภาพ.",
          "คุณแน่ใจที่จะลบรูปภาพนี้?"
        )
      ) {
        this.deletePhoto(photo);
      }
    },
    deletePhoto: async function (photo) {
      this.loading = true;
      axios
        .delete("/customer-meeting-photo/" + photo.id)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.deletePhotoResponse = response.data;
          this.loading = false;
          if (this.deletePhotoResponse.status === "success") {
            this.getDataFromAPI();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
