<template>
  <v-container>
    <v-row wrap dense>
      <v-col dense cols="12" xs="12" sm="12" md="12" lg="12" xl="12" grow pa-1>
        <customer-meeting-data-card :customerMeeting="customerMeeting" :loading="loading" />
      </v-col>
    </v-row>
    <v-row wrap dense>
      <v-col>
        <customer-meeting-photo :id="this.id"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";

import CustomerMeetingDataCard from "./CustomerMeetingDataCard.vue";
import CustomerMeetingPhoto from "./CustomerMeetingPhoto.vue";

moment.locale("th");

export default {
  name: "CustomerMeetingShow",
  components: {
    CustomerMeetingDataCard,
    CustomerMeetingPhoto,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    id: {
      type: String,
    },
  },
  data() {
    return {
      customerMeeting: {
        date: "",
        description: "",
      },
      loading: false,
    };
  },
  created() {
    this.getDataFromAPI();
  },
  methods: {
    getDataFromAPI() {
      this.loading = true;
      axios
        .get("/customer-meeting/" + this.id)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.customerMeeting = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
  },
};
</script>