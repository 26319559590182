<template>
  <v-container>
    <v-card :loading="loading">
      <v-toolbar color="primary">
        <v-toolbar-title>
          <v-icon medium> mdi-handshake </v-icon>
        การเข้าพบลูกค้า
        {{
          customerMeeting.customer ? customerMeeting.customer.name : ""
        }}
        เมื่อ {{ formatDate(customerMeeting.date) }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          small
          color="secondary"
          class="ma-2"
          outlined
          :to="`/customer-meeting/${customerMeeting.id}/edit`"
        >
          <v-icon small> mdi-pencil </v-icon>
          Edit
        </v-btn>       
      </v-toolbar>
      <!-- <v-card-title>
        <v-icon medium> mdi-handshake </v-icon>
        การเข้าพบลูกค้า
        {{
          customerMeeting.customer ? customerMeeting.customer.name : ""
        }}
        เมื่อ {{ formatDate(customerMeeting.date) }}
        <v-spacer />
        <v-btn
          small
          color="secondary"
          class="ma-2"
          outlined
          :to="`/customer-meeting/${customerMeeting.id}/edit`"
        >
          <v-icon small> mdi-pencil </v-icon>
          Edit
        </v-btn>
      </v-card-title>
      <v-divider /> -->
      <v-card-text v-if="loading == false">
        <v-row>
          <v-col class="py-3" cols="12">
            <!-- <TiptapVuetify v-model="customerMeeting.description" :extensions="extensions"/> -->
            <div
              class="tiptap-vuetify-editor__content text--primary"
              v-html="customerMeeting.description"
            ></div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";
// import {
//   TiptapVuetify,
//   Heading,
//   Bold,
//   Italic,
//   Strike,
//   Underline,
//   Code,
//   Paragraph,
//   BulletList,
//   OrderedList,
//   ListItem,
//   Blockquote,
//   HardBreak,
//   HorizontalRule,
//   History,
// } from "tiptap-vuetify";

moment.locale("th");
export default {
  name: "CustomerMeetingDataCard",
  props: {
    customerMeeting: {
      type: Object,
      require: true,
      default: () => {
        return {
          date: "",
          description: "",
          customer: {
            name: "",
          },
        };
      },
    },
    loading: {
      type: Boolean,
    },
  },
  // components: { TiptapVuetify },
  data() {
    return {
      // extensions: [
      //   History,
      //   Bold,
      //   Italic,
      //   Underline,
      //   Strike,
      //   Blockquote,
      //   ListItem,
      //   BulletList,
      //   OrderedList,
      //   [
      //     Heading,
      //     {
      //       options: {
      //         levels: [1, 2, 3],
      //       },
      //     },
      //   ],
      //   Code,
      //   HorizontalRule,
      //   Paragraph,
      //   HardBreak,
      // ],
    };
  },
  methods: {
    formatDate(date) {
      if (date !== null) {
        // return `${moment(date).format("LL")}`;
        return new Date(date).toLocaleDateString("th-TH", {
          dateStyle: "long",
        });
      }
      return null;
    },
  },
};
</script>
