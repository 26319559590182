<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="text-body-2 font-weight-bold grey--text">
          Upload photo
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form">
          <v-file-input
            v-model="images"
            ref="images"
            :rules="rules"
            small-chips
            multiple
            counter
            color="secondary"
            accept=".png, .jpg, .jpeg"
            prepend-icon="mdi-image-multiple"
            required
          ></v-file-input>
        </v-form>
        <br />
        <v-progress-linear
          :active="uploading"
          v-model="uploadPercentage"
          height="25"
        >
          <strong> {{ uploadPercentage }}% </strong>
        </v-progress-linear>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          class="body-2 font-weight-bold"
          outlined
          @click="upload()"
          >Upload</v-btn
        >
        <v-btn
          color="grey"
          text
          class="body-2 font-weight-bold"
          @click="cancel()"
          >Cancel</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "UploadPhotoDialog",
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      id: null,
      images: null,
      uploadPhotoResponse: null,
      uploading: false,
      uploadPercentage: 0,
      options: {
        color: "primary",
        width: 400,
        zIndex: 200,
      },
      rules: [
        (value) => !!value || "Photo is required",
        (value) =>
          !value ||
          value.some((file) => file.size < 10000000) ||
          "Photo size should be less than 10 MB!",
        (value) =>
          !value || value.length <= 20 || "Maximum photo is 20 photos!",
      ],
    };
  },
  methods: {
    open: function(id) {
      this.dialog = true;
      this.id = id;
      // this.customerMeeting = customerMeeting;
      // console.log(albumIndex);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    upload() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        for (let image of this.images) {
          formData.append("upload", image, image.name);
        }
        // console.log(formData);
        axios
          .post(
            "/customer-meeting/" + this.id + "/upload",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              onUploadProgress: function(progressEvent) {
                this.uploading = true;
                this.uploadPercentage = parseInt(
                  Math.round((progressEvent.loaded / progressEvent.total) * 100)
                );
              }.bind(this),
            }
          )
          .then((response) => {
            this.uploadPhotoResponse = response.data;
            if (this.uploadPhotoResponse.status === "success") {
              this.uploading = false;
              this.loading = false;
              this.$refs.form.reset();
              this.resolve(true);
              this.dialog = false;
              this.$emit("photo-uploaded");
            }
          })
          .catch(function() {});
      }
    },
    cancel() {
      this.$refs.form.reset();
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
